import {createVNode, render} from "vue";

export default function (modalCom, options = {}, appendTo = document.body) {
    return new Promise((resolve) => {
        const container = document.createElement("div");
        options.onClosed = () => {
            render(null, container);
        };
        options.onCancel = () => {
            resolve();
        };
        options.onSubmit = (data) => {
            resolve(data);
        };
        const vnode = createVNode(modalCom, options);
        render(vnode, container);
        appendTo.appendChild(container.firstElementChild);
    });
}
